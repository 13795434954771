import React from 'react'
import Plx from 'react-plx';
//import Link from 'gatsby-link';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import ColorUtils from '../utils/colorUtils'
import MouseButton from '../components/mouseButton'
import { Helmet } from "react-helmet"
import SEO from '../components/seo'

//Assets
import PortraitAdrienne from "../images/portrait_adrienne.jpg"
import PortraitOren from "../images/portrait_oren.jpg"
import PortraitYan from "../images/portrait_yan.jpg"
import BannerWhoweare from "../images/cep_banner_whoweare.jpg"

//Logo Paths
import logoNIA from "../images/logo-nia.png"
import logoBJ from "../images/logo-bj.png"
import logoSF from "../images/logo-sf.png"
import logoSLG from "../images/logo-slg.png"
//import logoON from "../images/logo-on.png"
import logoSB from "../images/logo-sb.png"
import logoCB from "../images/logo-cb.png"
import logoCW from "../images/logo-cw.png"
import logoSC from "../images/logo-sc.png"
import logoSU from "../images/logo-su.png"
import logoAO from "../images/logo-ao.png"
import logoSW from "../images/logo-sw.png"
import logoBM from "../images/logo-bm.png"
import logoSK from "../images/logo-sk.png"
import logoHF from "../images/logo-hf.png"
import logoMD from "../images/logo-md.png"
import logoMA from "../images/logo-ma.png"
import logoUT from "../images/logo-ut.png"
import logoIL from "../images/logo-il.png"
import logoIN from "../images/logo-in.png"
import logoVS from "../images/logo-vs.png"

const sliderParallaxData = [
  {
    start: 'self',
    duration: '100vh',
    properties: [
      {
        startValue: 100,
        endValue: 0,
        unit: 'vh',
        property: 'height'
      }
    ]
  }
]


const MASONARY_DURATION = '20vh'

const masonaryTile1ParallaxData = [
  {
    start: 'self',
    startOffset: '5vh',
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const bioTile1ParallaxData = [
  {
    start: 'self',
    startOffset: '30vh',        
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const bioTile2ParallaxData = [
  {
    start: 'self',
    startOffset: '40vh',    
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]
const bioTile3ParallaxData = [
  {
    start: 'self',
    startOffset: '50vh',    
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]
const masonaryTile2ParallaxData = [
  {
    start: 'self',
    startOffset: '10vh',
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const masonaryTile3ParallaxData = [
  {
    start: 'self',
    startOffset: '15vh',
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const masonaryTile4ParallaxData = [
  {
    start: 'self',
    startOffset: '20vh',
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const masonaryTile5ParallaxData = [
  {
    start: 'self',
    startOffset: '25vh',
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]




const masonaryHeaderCenterParallaxData = [
  {
    start: 'self',
    duration: '60vh',
    properties: [
      {
        startValue: -25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const masonaryHeaderCenterTwoParallaxData = [
  {
    start: 'self',
    startOffset: '30vh',
    duration: '60vh',
    properties: [
      {
        startValue: -5,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]
const masonaryHeaderParallaxData = [
  {
    start: 'self',
    duration: '60vh',
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const masonaryHeaderRightParallaxData = [
  {
    start: 'self',
    duration: '60vh',
    properties: [
      {
        startValue: -5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]
const verticalLineMasonaryParallaxData = [
  {
    start: 'self',
    startOffset: '30vh',
    duration: '70vh',    
    properties: [
      {
        startValue: 0,
        endValue: 100,
        unit: 'vh',
        property: 'height',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]


const verticalLineLeadershipParallaxData = [
  {
    start: 'self',
    startOffset: '30vh',
    duration: '75vh',
    properties: [
      {
        startValue: 0,
        endValue: 125,
        unit: 'vh',
        property: 'height',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]



export default class ParallaxPage extends React.Component {
  constructor(props) {
    super(props)
  
    //Video Reference
    this.VideoRef = React.createRef()
    this.state = {
      threeColorOne: [57,1,100],
      threeColorTwo: [236,47,75],
      wrapperBackground: 'Purple'
     }
     this.colorUtils = new ColorUtils()
  }

  componentDidMount() {
  }


  playVideo(theVideo) {
    if(theVideo.current) {
      theVideo.current.play()
    }
  }
  
  pauseVideo(theVideo) {
    if(theVideo.current) {
      theVideo.current.pause()
    }
  }




  render() {
    return (
    <>
        <SEO title="Who We Are" />
        <Helmet>
          <title>Who We Are</title>
        </Helmet>       
        {/*Slider Block*/}
        <div className="ContentBlock Centered SliderBlock">
        <Plx parallaxData={ sliderParallaxData }>
          <div className="TopBanner ImageBanner" style={{backgroundImage: `url(${BannerWhoweare})`}}>
            <div>
            <div className="ImageOverlay Percent15"></div>
              <h4>Strategic vision, creative rigour, full integration, and deep in-house capabilities.</h4>
            </div>
          </div>
        </Plx>
        <MouseButton></MouseButton>

        </div>


      {/*We are a strategic brand and design development studio*/}
        <div className="ContentBlock Centered MasonaryBlock PurpleBlock SecondBlock" id="SecondBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderParallaxData} className="DisablePlxMobile">
          <h1>We are a strategic brand and design development studio</h1>
          </Plx>

          <div className="MasonaryContent">
          <p>We provide Brand advisory, strategic marketing, and creative services. <strong>Full integration</strong> is core to the strength we bring to the table – strategy, design, production and web development are all done in-house. <strong>There is no ‘B’ team</strong> – you always get our top talent and resources, so there is never compromise on quality or continuity. <strong>We deliver on time and on budget</strong>. </p>
            <AniLink cover bg={this.colorUtils.getRandomGradientCSS()}  to="/services/" className="ArrowLink">
              <h3>Services</h3>
            </AniLink>
          </div>
  
        </div>

        {/*We are a strategic brand and design development studio*/}
        <div className="ContentBlock Centered MasonaryBlock RightBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderRightParallaxData}>
          <h1>Experience in a diverse range of industries</h1>
          </Plx>

          <div className="MasonaryContent">
          <p>Cyber security, professional services, financial services, healthcare technology and services, real estate, retirement residences, staffing, consumer packaged goods, hospitality, food & beverage, casino gaming, theatre, consumer electronics, fitness products, cosmetics, and more.</p>
          </div>

        </div>


        {/*Human Infrastructure*/}
      <div className="ContentBlock Centered MasonaryBlock BlackBlock LeadershipBlock">

          <Plx parallaxData={masonaryHeaderCenterParallaxData}>
          <h1 className="Centered FullWidth">Human Infrastructure</h1>
          </Plx>

          <div className="MasonaryContent FullWidth">
            <p>A diverse understanding of people, markets and industries is crucial to the task of communicating value.</p>
            <p>Our team is rigorous and culturally attuned, with backgrounds ranging from industrial design, UX design, business development, and technology, to literature, material culture, fine art, and choreography.</p>
            <p>Our mission is to understand your needs, adapt models and resources to your goals, execute obsessively, and respond with creative courage and process rigour.</p>
          </div>

          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineLeadershipParallaxData}>
              <div className="VerticalLine"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderCenterTwoParallaxData}>
          <h1 className="Centered FullWidth">Leadership</h1>
          </Plx>
          <div className="MasonaryContainer">
            <div className="MasonaryTile BioTile">
              <Plx parallaxData={ bioTile1ParallaxData } >
                <img src={PortraitOren} alt="Oren Katz"/>              
              </Plx>
              <Plx parallaxData={ masonaryTile1ParallaxData } className="BioTileBio">
              
              <h3>Oren Katz</h3>
              <h4>Partner, Design</h4>
              <p><span className="MediumWeight">Clients and colleagues know Oren for his calm optimism, hands-on style and obsessive execution.</span> He began his career designing concept vehicles at General Motors, as a store designer with iconic retailer Boutique Jacob, and later developing brand and retail typologies with Bruce Mau Design. Oren is an experienced designer, strategist, art director, and writer; at CEP he leads the delivery of a full suite of integrated services across brand development, creative, digital platforms and assets, campaign development and sales enablement programs. Oren completed a BA at McGill University in Montreal and a Master of Industrial Design at Pratt Institute in New York. He is fluent in English and French.</p>
              </Plx>
            </div>
            <div className="MasonaryTile BioTile">
              <Plx parallaxData={ bioTile2ParallaxData } >
              <img src={PortraitAdrienne} alt="Adrienne Katz"/>
              </Plx>
              <Plx parallaxData={ masonaryTile2ParallaxData }  className="BioTileBio">
              
              <h3>Adrienne Katz</h3>
              <h4>Partner, Strategy</h4>
              <p><span className="MediumWeight">Clients and colleagues know Adrienne for her unstoppable momentum, uncanny judgement and ability to galvanize a plan into action.</span> She began her career in mobile banking services and then brand management at General Mills. She has led major strategic initiatives with public and private corporations, entrepreneurs, foundations, and not-for-profit organizations. She is an experienced leader in the areas of marketing strategy and planning, and successfully manages brands using an insights-based approach. Adrienne completed her MBA at McGill University in Montreal.</p>
              </Plx>
            </div>
            <div className="ClearBoth"></div>
            <div className="MasonaryTile BioTile">
              <Plx parallaxData={ bioTile3ParallaxData } >
                <img src={PortraitYan} alt="Yan Chu"/>              
              </Plx>
              <Plx parallaxData={ masonaryTile3ParallaxData }  className="BioTileBio">
              <h3>Yan Chu</h3>
              <h4>Senior Designer / Developer</h4>
              <p><span className="MediumWeight">Clients and colleagues know Yan for his ability to enable strategic creative with technology, and for his studied international fashion sensibility.</span> Yan’s abilities reflect our shared belief that integrated capabilities deliver the strongest work and the best results. His abilities are the convergence of creative and technical, and his successes include rich strategic creative, a deep knowledge of best practices across channels and mediums, and rigorous technical expertise. He has extensive experience in the development and operationalization of creative and marketing assets. Yan completed a Bachelor of Computer Science at York University in Toronto. He is fluent in English and Cantonese.</p>
              </Plx>
            </div>
          </div>
        </div>

        {/*Organizations we’ve helped:*/}
        <div className="ContentBlock Centered MasonaryBlock">
          <div className="VerticalLineContainer MarginTop15">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderCenterParallaxData}>
          <h1 className="Centered FullWidth">Organizations we’ve helped:</h1>
          </Plx>


          <div className="MasonaryContainer ">
            {/*Row #1*/}
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile1ParallaxData }>
              
                <img src={logoBJ} alt="Bennett Jones"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile2ParallaxData }>
                              
                <img src={logoAO} alt="Aon"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile3ParallaxData }>
                              
                <img src={logoSF} alt="Stroz Friedberg"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile5ParallaxData }>
                              
                <img src={logoSB} alt="Starbucks"/>
                
              </Plx>
            </div>

            {/*Row #2*/}
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile1ParallaxData }>
                          
              <img src={logoSLG} alt="Sutherland Global"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile2ParallaxData }>
                              
                <img src={logoCW} alt="Chartwell"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile3ParallaxData }>
                     
              <img src={logoNIA} alt="National Institute On Ageing"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile4ParallaxData }>
                       
              <img src={logoSK} alt="Sickkids"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile5ParallaxData }>
                
              <img src={logoIN} alt="Canada Health Infoway"/>
                
              </Plx>
            </div>

            {/*Row #3*/}

            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile4ParallaxData }>
                <img src={logoSC} alt="Street Capital Bank"/>                
              </Plx>
            </div>

            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile1ParallaxData }>
                        
              <img src={logoHF} alt="Habitat for humanity"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile2ParallaxData }>
                              
                <img src={logoBM} alt="Bruce Mau Design"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile3ParallaxData }>
                              
              <img src={logoSW} alt="Stagewest Hospitality"/>              
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile4ParallaxData }>
                              
              <img src={logoCB} alt="Cassel Brock"/>
              
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile5ParallaxData }>
                              
                <img src={logoSU} alt="Subway"/>                

              </Plx>
            </div>

            {/*Row #4*/}
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile1ParallaxData }>
                              
                <img src={logoMA} alt="Mastercard Foundation"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile2ParallaxData }>
                              
                <img src={logoUT} alt="niversity of Toronto Scarborough"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile3ParallaxData }>
                              
                <img src={logoIL} alt="ITW"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile4ParallaxData }>
                              
              <img src={logoVS} alt="Verras"/>
                
              </Plx>
            </div>
            <div className="MasonaryTile MT20">
              <Plx parallaxData={ masonaryTile5ParallaxData }>
                              
                <img src={logoMD} alt="March of domes Canada"/>
               
              </Plx>
            </div>

          </div>
        </div>

        {/*Client Have Called Us*/}
        <div className="ContentBlock Centered MasonaryBlock PurpleBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderParallaxData}>
          <h1>Clients have<br/>called us</h1>
          </Plx>

          <div className="MasonaryContent">
            <p>“talented and cutting edge,” with “an innate ability to assess a complex business challenge and provide solutions which are not only creative, but strategically sound.” Our design and branding work has been praised for “inspiring instant recognition in the marketplace,” and we have cultivated a reputation for delivering “the best possible result, on time and within the budget…  In addition, they are always a pleasure to work with.”</p>
          </div>

        </div>
        </>
      )
    }
}